import { css } from '@emotion/react/macro';
import { theme } from 'src/theme';
import { headingTheme } from 'src/theme/tokens/components/heading';
import { textTheme } from 'src/theme/tokens/components/text';
import { HeadingStyleArgs } from 'src/components/ui/typography/heading/Heading.types';
import { getTypographyVariantsValues } from '../utils/Typography.helper';

export const headingStyle = ({
  type,
  align,
  noWrap,
  color,
  noMarginBottom,
  weight,
}: HeadingStyleArgs) => css`
  font-weight: ${theme.fonts.weights[weight]};
  font-family: ${theme.fonts.family.heading}, sans-serif;
  ${!noMarginBottom && `margin: 0 0 ${theme.spacing.s} 0`};
  ${color && `color: ${textTheme.colors[color]}`};
  ${align && `text-align: ${align}`};
  ${noWrap && `white-space: ${noWrap}`};
  ${getTypographyVariantsValues({ variant: headingTheme, type, device: 'mobile' })};

  ${theme.breakpoints.tablet} {
    ${getTypographyVariantsValues( {variant: headingTheme, type, device: 'desktop' })};
  }

  ${theme.breakpoints.upMedium} {
    ${getTypographyVariantsValues({ variant: headingTheme, type, device: 'desktop' })};
  }
`;
