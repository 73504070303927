import styled from '@emotion/styled/macro';
import { dividerTheme } from 'src/theme/tokens/components/divider';
import { WithRequired } from 'src/typings/utils';
import * as T from './Divider.types';

export const Divider = styled.hr<WithRequired<T.DividerProps, 'variant'>>`
  background-color: ${({ variant }) => dividerTheme.variants[variant]};
  border-radius: ${({ theme }) => theme.radius.full};
  height: ${({ word }) => (word ? '1px' : '2px')};
  margin: ${({ margin, theme }) => `${theme.spacing[margin]} 0`};
  width: ${({ size, theme }) => (size === 'small' ? `${theme.spacing.l}` : '100%')};
`;

export const DividerContainer = styled.div<T.DividerProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const DividerWord = styled.div<WithRequired<T.DividerProps, 'wordBackground'>>`
  background-color: ${({ wordBackground }) => dividerTheme.variants[wordBackground]};
  padding: ${({ theme }) => theme.spacing.xs};
  position: absolute;
`;
