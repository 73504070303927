import { TextType } from './Text.types';
import { Tag } from '../utils/Typography.types';

/**
 * FIXME: if we look at getTypographyVariantsValues(), we have mixed sizes and default html elements.
 * add unset type to handle block and inline elements.
 */
export const TYPE_TO_TAG: { [key in TextType]: Tag } = {
  xl: 'p',
  l: 'p',
  m: 'p',
  s: 'span',
  xs: 'span',
  '2xs': 'span',
  '3xs': 'span',
} as const;
