import React from 'react';
import { useIntl } from 'react-intl';
import Text from 'src/components/ui/typography/text/Text';
import Divider from 'src/components/ui/divider/Divider';
import sharedMessages from 'src/hocs/App/messages';
import Link from 'src/components/ui/link/Link';
import * as T from './types';
import * as S from './styles';
import messages from './messages';

export const Footer = ({ hasCatchPhrase = true, linkList, hasStickyBanner }: T.FooterTypes) => {
  const { formatMessage } = useIntl();

  return (
    <S.Footer linkList={linkList} hasStickyBanner={hasStickyBanner}>
      {hasCatchPhrase && (
        <>
          <Text type="l" weight="bold" align="center">
            {formatMessage(messages.catchPhrase)}
          </Text>
          <Divider variant="tertiary" />
        </>
      )}
      <S.FooterLinkList>
        {linkList.map(({ key, message, href }) => (
          <S.FooterLinkListItem key={key}>
            <Link
              data-testid="test"
              target="_blank"
              href={href}
              linkText={formatMessage(message)}
              chevron="none"
              variants="secondary"
              isUnderlined={false}
            />
          </S.FooterLinkListItem>
        ))}
        <S.FooterLinkListItem>
          <S.FooterCopyright type="s">
            {formatMessage(messages.copyright, {
              currentYear: new Date().getFullYear(),
              tenantName: formatMessage(sharedMessages.tenantName),
            })}
          </S.FooterCopyright>
        </S.FooterLinkListItem>
      </S.FooterLinkList>
    </S.Footer>
  );
};
