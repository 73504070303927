import styled from '@emotion/styled/macro';
import { textTheme } from 'src/theme/tokens/components/text';
import { GenericIconProps } from 'src/components/ui/icons/Icon.types';

export const Path = styled.path<GenericIconProps>`
  ${({ fillColor }) => (fillColor !== undefined ? `fill: ${textTheme.colors[fillColor]};` : '')}
  ${({ strokeColor }) =>
    strokeColor !== undefined ? `stroke: ${textTheme.colors[strokeColor]};` : ''}
`;

export const SVG = styled.svg<GenericIconProps>`
  ${({ isMiddleVerticalAlign }) => isMiddleVerticalAlign && 'vertical-align: middle'}
`;
