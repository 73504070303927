import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getInputPropsFromName } from 'src/utils/form/form.helper';
import { FormDataType } from 'src/utils/form/form.helper.types';
import Heading from 'src/components/ui/typography/heading/Heading';
import Text from 'src/components/ui/typography/text/Text';
import Button from 'src/components/ui/button/Button';
import { InputWithButton } from 'src/components/molecules/InputWithButton';
import { Loader } from 'src/components/ui/loader/Loader';
import { usePauseSubscription } from 'src/hocs/WithSubscriptionNew/hooks';
import { SUBSCRIPTION_FIELDS } from 'src/hocs/WithSubscriptionNew/constants';
import {
  makeSelectSubscription,
  makeSelectIsSubscriptionLoading,
} from 'src/hocs/WithSubscriptionNew/selectors';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import { PauseSubscriptionFormSchema } from './PauseSubscriptionForm.schema';
import * as S from './PauseSubscriptionForm.styles';

export interface PauseSubscriptionFormType {
  [SUBSCRIPTION_FIELDS.EMAIL]: string;
}

export const PauseSubscriptionForm = () => {
  const { intlMessage } = useIntlMessage();
  const [hasSaved, setHasSaved] = useState<string | null>(null);
  const fetchPauseSubscription = usePauseSubscription();
  const subscription = useSelector(makeSelectSubscription());
  const loading = useSelector(makeSelectIsSubscriptionLoading());
  const pauseSubscriptionForm = useForm<PauseSubscriptionFormType>({
    mode: 'onSubmit',
    resolver: yupResolver(PauseSubscriptionFormSchema()),
  });

  // constants
  const defaultValues = {
    [SUBSCRIPTION_FIELDS.EMAIL]: subscription?.email,
  };
  const formData: FormDataType = {
    ...pauseSubscriptionForm,
    defaultValues,
  };
  const onSubmit = async (values: PauseSubscriptionFormType) => {
    await fetchPauseSubscription(values?.email);
    setHasSaved(values?.email);
  };

  const handleClose = () => {
    // navigate to root domain
    const splitHost = window.location.hostname.split('.');
    // delete subdomains if they exist
    if (splitHost.length >= 3) {
      splitHost.shift();
    }
    const rootDomain = splitHost.join('.');
    const locationProtocol = `${window.location.protocol}//`;
    // This code will not work in local, since there is no .fr suffix
    window.location.assign(`${locationProtocol}${rootDomain}`);
  };

  return (
    <>
      {hasSaved && (
        <>
          <Heading type="h4">{intlMessage('app.SaveProgression.titleStep2')}</Heading>
          <Text type="l">{intlMessage('app.SaveProgression.subTitleStep2')}</Text>
          <Text>{hasSaved}</Text>
          <br />
          <Button onClick={handleClose} label={intlMessage('shared.i18n.ok')} />
        </>
      )}
      {!hasSaved && (
        <>
          <Heading type="h4">{intlMessage('app.pauseSubscriptionForm.title')}</Heading>
          <Text type="l">{intlMessage('app.pauseSubscriptionForm.subTitle')}</Text>
          <br />
          <form onSubmit={formData.handleSubmit(onSubmit)}>
            <InputWithButton>
              <S.CustomInput
                {...getInputPropsFromName(SUBSCRIPTION_FIELDS.EMAIL, formData)}
                icon={loading && <Loader />}
                isInputWithButton
              />
              <Button
                type="submit"
                disabled={loading}
                label={intlMessage('app.pauseSubscriptionForm.saveSubscription')}
                isInputWithButton
              />
            </InputWithButton>
          </form>
        </>
      )}
    </>
  );
};
