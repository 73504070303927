import { BUSINESS_PARTS } from 'src/hocs/App/constants';
import { BUSINESS_PARTNERS_LIST } from 'src/components/organisms/Header/Header.constants';
import Button from 'src/components/ui/button/Button';
import { useSelector } from 'react-redux';
import { makeSelectCustomerService } from 'src/hocs/WithCustomerService/selectors';
import gtm from 'src/utils/gtm';
import { Phone } from 'src/components/ui/icons/phone/Phone';
import { Mail } from 'src/components/ui/icons/mail/Mail';
import { GenericIconProps } from 'src/components/ui/icons/Icon.types';
import { ButtonSizes } from 'src/components/ui/button/Button.types';

import {
  makeIsProfessional,
  makeSelectCustomerType,
} from 'src/hocs/WithUserInformations/selectors';
import * as S from '../Header.styles';

interface HeaderContactProps {
  businessPart: string;
  isDesktop: boolean;
}

interface ContactButtonProps {
  ariaLabel: string;
  dataTestid: string;
  href: string;
  icon: React.FC<GenericIconProps>;
  label: string;
  onClick?: () => void;
  size: ButtonSizes;
}

const ContactButton = ({
  ariaLabel,
  dataTestid,
  href,
  icon,
  label,
  onClick = null,
  size,
}: ContactButtonProps): JSX.Element => (
  <S.PhoneButtonContainer>
    <Button
      aria-label={ariaLabel}
      as="a"
      data-testid={dataTestid}
      href={href}
      icon={icon}
      label={label}
      onClick={onClick}
      variant="outlined"
      size={size}
      target="_self"
    />
  </S.PhoneButtonContainer>
);

const HeaderContact = ({ businessPart, isDesktop }: HeaderContactProps): JSX.Element => {
  const selectraGroupPurchaseFiltered = Object.values(BUSINESS_PARTS.SELECTRA_GROUP_PURCHASE).slice(
    1,
  );
  const hidePhoneNumber = Object.values(BUSINESS_PARTNERS_LIST).includes(businessPart);
  const { emailAddress, isAllContactChannel, phoneNumber, isPhoneEnabled } = useSelector(
    makeSelectCustomerService(),
  );
  const isProfessional = useSelector(makeIsProfessional());
  const customerType = useSelector(makeSelectCustomerType());

  const isSelectraGroupPurchasePhoneHidden = selectraGroupPurchaseFiltered.some(
    (selectraBusinessPart) => businessPart === selectraBusinessPart,
  );
  const hasPhoneToDisplay = (!hidePhoneNumber && isAllContactChannel) || isPhoneEnabled;
  const size = isDesktop ? 'm' : 's';

  if (isSelectraGroupPurchasePhoneHidden) return null;
  return hasPhoneToDisplay ? (
    <ContactButton
      ariaLabel={phoneNumber?.replace(/ /g, '')}
      dataTestid="phoneButton"
      href={`tel:${phoneNumber?.replace(/ /g, '')}`}
      icon={!isDesktop && Phone}
      label={isDesktop ? phoneNumber : ''}
      onClick={() =>
        gtm.phoneClick({
          isProfessional,
          customerType,
          businessPart,
        })
      }
      size={size}
    />
  ) : (
    <ContactButton
      ariaLabel={emailAddress}
      dataTestid="mailButton"
      href={`mailto:${emailAddress}`}
      icon={!isDesktop && Mail}
      label={isDesktop ? emailAddress : ''}
      size={size}
      onClick={() =>
        gtm.emailClick({
          isProfessional,
          customerType,
          businessPart,
        })
      }
    />
  );
};

export default HeaderContact;
