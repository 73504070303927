import { HeadingType } from 'src/components/ui/typography/heading/Heading.types';
import { TextType } from 'src/components/ui/typography/text/Text.types';
import { headingTheme } from 'src/theme/tokens/components/heading';
import { textTheme } from 'src/theme/tokens/components/text';

type DeviceVariantArgs = {
  device: 'mobile' | 'desktop';
};

type TextVariantArgs = {
  variant: typeof textTheme;
  type?: TextType;
};

type HeadingVariantArgs = {
  variant: typeof headingTheme;
  type?: HeadingType;
};

export type GetTypographyVariantsArgs = DeviceVariantArgs & (TextVariantArgs | HeadingVariantArgs);

export const getTypographyVariantsValues = ({ variant, type, device }: GetTypographyVariantsArgs) =>
  variant[device][type];
