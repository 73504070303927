import styled from '@emotion/styled/macro';
import Grid from 'src/components/ui/layouts/grid/Grid';

/**
 * TODO this should be done in a more styled-component style...
 * We should not use any class at all!
 */

export const Row = styled(Grid)`
  &.Row {
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &.Row--wrap {
    flex-wrap: wrap;
  }

  &.Row--noWrap {
    flex-wrap: nowrap !important;
  }

  &.Row--left {
    justify-content: flex-start;
  }
  &.Row--center {
    justify-content: center;
  }
  &.Row--right {
    justify-content: flex-end;
  }
  &.Row--around {
    justify-content: space-around;
  }
  &.Row--between {
    justify-content: space-between;
  }
  &.Row--top {
    align-items: flex-start;
  }

  &.Row--topBetween {
    align-items: flex-start;
    justify-content: space-between;
  }

  &.Row--topEven {
    align-items: flex-start;
    justify-content: space-evenly;
  }

  &.Row--bottom {
    align-items: flex-end;
  }

  &.Row--bottomBetween {
    align-items: flex-end;
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    &.Row {
      flex-wrap: wrap;
    }

    &.Row.Row--noWrap {
      flex-wrap: nowrap !important;
    }
  }
`;
