import React from 'react';
import * as S from './InputLabel.styles';
import * as T from './InputLabel.types';

const InputLabel = ({ children, disabled, error }: T.InputLabelType) => (
  <S.Label disabled={disabled} error={error}>
    {children}
  </S.Label>
);

export default InputLabel;
