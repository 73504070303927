import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';
import { semantical } from 'src/theme/tokens/semantical';

import { Chevron } from 'src/components/ui/icons/chevron/Chevron';
import { AppTheme } from 'src/theme';
import { invalidAttributeInjectionGuardian } from 'src/utils/styles/styles.helper';
import * as T from './Link.types';

const linkTheme = {
  primary: {
    default: semantical.colors.text.interaction.default,
    interaction: semantical.colors.text.interaction.active,
  },
  secondary: {
    default: semantical.colors.text.neutral.weakest,
    interaction: semantical.colors.text.neutral.weakest,
  },
} as const;


const commonStyle = (
  chevron: T.LinkCommonProps['chevron'],
  variants: 'primary' | 'secondary',
  theme: AppTheme,
  isUnderlined: T.LinkCommonProps['isUnderlined'],
) => css`
  align-items: center;
  color: ${linkTheme[variants]?.default};
  display: inline-flex;
  text-decoration: ${isUnderlined ? 'underline' : 'none'};
  transition-property: ${theme.transitions.transitionProperty.colors};
  transition-duration: ${theme.transitions.duration.faster};
  transition-timing-function: ${theme.transitions.transitionTimingFunction['in-out']};
  flex-direction: ${chevron === 'left' ? 'row-reverse' : 'row'};
  font-size: ${theme.fonts.sizes.xs};
  font-weight: ${variants === 'secondary' ? 'normal' : theme.fonts.weights.semibold};
  line-height: ${theme.fonts.lineHeights['3xs']};

  ${theme.breakpoints.tablet} {
    font-size: inherit;
    line-height: inherit;
  }

  &:hover {
    color: ${linkTheme[variants]?.interaction};
    cursor: pointer;
  }
`;

export const RouterLink = styled(Link, invalidAttributeInjectionGuardian())<T.LinkCommonProps>`
  ${({ chevron, variants, theme, isUnderlined }) =>
    commonStyle(chevron, variants, theme, isUnderlined)};
`;

export const ClassicLink = styled('a', invalidAttributeInjectionGuardian())<T.LinkCommonProps>`
  ${({ chevron, variants, theme, isUnderlined }) =>
    commonStyle(chevron, variants, theme, isUnderlined)};
`;

export const ChevronLink = styled(Chevron, invalidAttributeInjectionGuardian())<T.LinkProps>`
  ${({ chevron }) => chevron === 'left' && 'transform: rotate(180deg);'};
`;

export const LinkContent = styled('span', invalidAttributeInjectionGuardian())<T.LinkStyleProps>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isReverted }) => (isReverted ? 'row' : 'row-reverse')};
`;

export const ChevronContainer = styled(
  'span',
  invalidAttributeInjectionGuardian(),
)<T.LinkStyleProps>`
  display: ${({ chevron }) => (chevron === 'none' ? 'none' : 'flex')};
  margin: ${({ isReverted, theme }) =>
    isReverted
      ? `0 ${theme.spacing['2xs']} 0 0`
      : `0 0 0 ${theme.spacing['2xs']}`};
  ${({ isReverted }) => isReverted && 'width: 24px; height: 24px;'}
`;
