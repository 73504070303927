import { useDispatch, useSelector, useStore } from 'react-redux';
import { AxiosResponse } from 'axios';
import { Url } from 'src/utils/routes/routes';
import { useRequest } from 'src/hooks/useRequest';
import { QuotationResponseType } from 'src/hocs/WithDevis/types';
import { constrainRequestPayload } from 'src/utils/typescript/typescript.helper';
import {
  makeSelectDevis,
  makeSelectMensuality,
  makeSelectMensualityIndex,
} from 'src/hocs/WithDevis/selectors';
import {
  makeSelectInvoiceFrequency,
  makeSelectSubscriptionId,
} from 'src/hocs/WithSubscriptionNew/selectors';

import API_ROUTES from 'src/utils/api/api-routes';
import { InvoicePaymentMethodEnum, InvoiceFrequencyEnum } from 'src/hocs/WithSubscriptionNew/types';
import http from 'src/utils/api/http';
import { useState } from 'react';
import { SUBSCRIPTION_FIELDS } from 'src/hocs/WithSubscriptionNew/constants';
import {
  getMandateRequestAction,
  getMandateSuccessAction,
  getMandateFailureAction,
  getMonthlyBillRequestAction,
  getMonthlyBillSuccessAction,
  getMonthlyBillFailureAction,
  getBillingMethodsRequestAction,
  getBillingMethodsSuccessAction,
  getBillingMethodsFailureAction,
} from './actions';

import * as t from './types';
import * as c from './constants';

const { billing = '' } = API_ROUTES;
const mensualityUrl = `${billing}/mensuality/:id`;
const mandateUrl = `${billing}/mandate`;
const billingModeUrl = `${billing}/param/:id`;

// useStore was chosen over useSelector hook for better performance
// Those hooks avoid calling all selectors on every re-render
// also there is no need to update UI components

interface UseGetMonthlyBillType {
  data: t.MonthlyBillResponseType;
  fetchUrl: () => Promise<void>;
  loading: boolean;
  error: AxiosResponse;
}

export const useGetMonthlyBill = (devisId?: string): UseGetMonthlyBillType => {
  const store = useStore();
  const state = store.getState();
  const dispatch = useDispatch();

  const devis: QuotationResponseType = makeSelectDevis()(state);
  const id = devis?.id || devisId;

  const onRequest = () => dispatch(getMonthlyBillRequestAction());
  const onSuccess = (response: t.MonthlyBillResponseType) =>
    dispatch(getMonthlyBillSuccessAction(response));
  const onFailure = (error: AxiosResponse) => dispatch(getMonthlyBillFailureAction(error));

  return useRequest(Url(mensualityUrl, { id }), 'get', null, onRequest, onSuccess, onFailure);
};

export const useGetMandate = (iban: string) => {
  const store = useStore();
  const state = store.getState();
  const subscriptionId: string = makeSelectSubscriptionId()(state);
  const dispatch = useDispatch();

  const getBody = () =>
    constrainRequestPayload<t.GetMandateRequestType>(
      {
        iban,
        subscriptionId,
      },
      c.GET_MANDATE_FIELDS,
    );
  const onRequest = () => dispatch(getMandateRequestAction());
  const onSuccess = (response: t.MandateResponseType) =>
    dispatch(getMandateSuccessAction(response));
  const onFailure = (error: AxiosResponse) => dispatch(getMandateFailureAction(error));

  return useRequest(mandateUrl, 'post', getBody, onRequest, onSuccess, onFailure);
};

export const useGetBillingMethods = (devisId?: string) => {
  const store = useStore();
  const state = store.getState();
  const dispatch = useDispatch();

  const devis: QuotationResponseType = makeSelectDevis()(state);
  const id = devis?.id || devisId;

  const onRequest = () => dispatch(getBillingMethodsRequestAction());
  const onSuccess = (response: t.BillingMethodsResponseType) =>
    dispatch(getBillingMethodsSuccessAction(response));
  const onFailure = (error: AxiosResponse) => dispatch(getBillingMethodsFailureAction(error));

  return useRequest(Url(billingModeUrl, { id }), 'get', null, onRequest, onSuccess, onFailure);
};

export const useGetBillingPaymentList = ({ billingPaymentListData }) => {
  const {
    invoiceFrequency: invoiceFrequencyChoice,
    invoicePaymentMethod: invoicePaymentMethodChoice,
    firstMonthlyPayment: mensualityChoice,
  } = billingPaymentListData;

  const subscriptionId = useSelector(makeSelectSubscriptionId());
  const initialInvoiceFrequency = useSelector(makeSelectInvoiceFrequency());
  const mensualities = useSelector(makeSelectMensuality());
  const mensualityIndex = useSelector(makeSelectMensualityIndex()) ?? 1;
  const invoiceFrequency = invoiceFrequencyChoice ?? initialInvoiceFrequency;
  const invoicePaymentMethod = invoicePaymentMethodChoice ?? InvoicePaymentMethodEnum.DOM;
  const mensualityAmount =
    (mensualities && mensualities[mensualityChoice]) ?? mensualities?.[mensualityIndex];

  const [url, setUrl] = useState(
    `${billing}/payment_list/${subscriptionId}?invoiceFrequency=${invoiceFrequency}&invoicePaymentMethod=${invoicePaymentMethod}&firstMonthlyPayment=${mensualityAmount}`,
  );
  const [apiResult, setApiResult] = useState(null);

  const getBillingPaymentList = async ({ value = null, param = null }) => {
    let urlToGet = url;
    let result = null;
    const invoiceFrequencyRegex = /(?:invoiceFrequency=)(.*)(?=&invoicePaymentMethod=)/g;
    const invoicePaymentMethodRegex = /(?:invoicePaymentMethod=)(.*)(?=&firstMonthlyPayment=)/g;
    const firstMonthlyPaymentRegex = /(?:firstMonthlyPayment=)(.*)/g;
    const invoiceFrequencyElement = url
      .match(invoiceFrequencyRegex)[0]
      .replace('invoiceFrequency=', '');
    const invoicePaymentMethodElement = url
      .match(invoicePaymentMethodRegex)[0]
      .replace('invoicePaymentMethod=', '');
    const firstMonthlyPaymentElement = url
      .match(firstMonthlyPaymentRegex)[0]
      .replace('firstMonthlyPayment=', '');

    switch (param) {
      case SUBSCRIPTION_FIELDS.INVOICE_FREQUENCY:
        if (value === InvoiceFrequencyEnum.ANN) {
          urlToGet = url
            .replaceAll(invoiceFrequencyElement, value)
            .replaceAll(invoicePaymentMethodElement, InvoicePaymentMethodEnum.DOM);
          setUrl(urlToGet);
        } else {
          urlToGet = url.replaceAll(invoiceFrequencyElement, value);
          setUrl(urlToGet);
        }
        break;
      case SUBSCRIPTION_FIELDS.INVOICE_PAYMENT_METHOD:
        urlToGet = url.replaceAll(invoicePaymentMethodElement, value);
        setUrl(urlToGet);
        break;
      case 'firstMonthlyPayment':
        // eslint-disable-next-line no-case-declarations
        const amount = mensualities[value];
        urlToGet = url.replaceAll(firstMonthlyPaymentElement, amount);
        break;
      default:
    }
    result = await http.get(urlToGet);
    return setApiResult(result.data);
  };

  return {
    setUrl,
    getBillingPaymentList,
    apiResult,
  };
};
