/**
 * We used the shoudlForwardProp function added by emotion.js to avoid non-standard attributes injections in HTML. The blackList
 * array contains all the non-standard attributes.
 */

const blackListProps = [
  'data-testId',
  'energyType',
  'hasBigIcon',
  'hasError',
  'hasIcon',
  'isButton',
  'isReverted',
  'isVisibleLabel',
  'isLightColor',
  'isStackedOnMobile',
  'isError',
  'label',
  'loading',
  'marginLeft',
  'isError',
  'labelTextmarginRight',
  'hasPartnerLogo',
  'isUnderlined',
];

/**
 * Returns an object with a shouldForwardProp method that guards against
 * injection of invalid attributes on DOM Elements.
 *
 * @returns {Object} An object with a shouldForwardProp method.
 */
export const invalidAttributeInjectionGuardian = () => ({
  /**
   * Determines if the provided prop should be forwarded to the wrapped component.
   * https://emotion.sh/docs/styled#customizing-prop-forwarding
   *
   * @param {string} prop - The name of the prop being checked.
   * @returns {boolean} True if the prop should be forwarded, false if it should be blocked.
   */
  shouldForwardProp: (prop: string) => !blackListProps.includes(prop),
});
