import styled from '@emotion/styled/macro';
import { ColumnStyleTypes } from './Column.types';
import Grid from '../grid/Grid';

export const Column = styled(Grid)<ColumnStyleTypes>`
  width: 100%;
  justify-content: ${({ justifyContentValue }) => justifyContentValue};
  align-content: ${({ alignContentValue }) => (alignContentValue ? 'flex-end' : 'center')};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItemsValue }) => alignItemsValue || null};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px !important` : null};
  padding: ${({ padding }) => padding || null};
  white-space: ${({ whiteSpace }) => whiteSpace || null};
`;
