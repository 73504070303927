import styled from '@emotion/styled/macro';
import { inputTextTheme } from 'src/theme/tokens/components/form/inputText';
import * as T from './InputLabel.types';

const { colors, variants } = inputTextTheme;

const getColor = ({ disabled, error }: T.GetInputColorArgs) => {
  if (error) {
    return colors.text.error;
  }
  if (disabled) {
    return colors.text.disabled;
  }
  return colors.text.default;
};

export const Label = styled.label<T.InputLabelType>`
  color: ${({ disabled, error }) => getColor({ error, disabled })};
  font-size: ${variants.mobile.labelFontSize};
  line-height: ${variants.mobile.lineHeight};
  display: inline-block;
  margin: ${inputTextTheme.labelMargin};
  width: 100%;

  ${({ theme }) => theme.breakpoints.upMedium} {
    font-size: ${variants.desktop.labelFontSize};
    line-height: ${variants.desktop.lineHeight};
  }
`;
