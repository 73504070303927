import { Loader } from 'src/components/ui/loader/Loader';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import * as S from './Button.styles';
import * as T from './Button.types';

const Button = ({
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  as = 'button',
  hasLeftIcon = false,
  hasMinWidth = true,
  icon: Icon,
  disabled = false,
  isLoading = false,
  isSelected = false,
  label = '',
  loadingLabel = '',
  size = 'm',
  variant = 'filled',
  isInputWithButton = false,
  ...otherProps
}: T.ButtonProps) => {
  const isVisibleLabel = !!label;
  const hasIcon = Icon || isLoading;
  const { intlMessage } = useIntlMessage();

  return (
    <S.Button
      aria-label={ariaLabel}
      as={as}
      size={size}
      variant={variant}
      disabled={disabled}
      isSelected={isSelected}
      isLoading={isLoading}
      isVisibleLabel={isVisibleLabel}
      data-testid={dataTestId}
      hasMinWidth={hasMinWidth}
      isInputWithButton={isInputWithButton}
      {...otherProps}
    >
      <S.ButtonContent hasLeftIcon={hasLeftIcon}>
        {isVisibleLabel && (
          <span>
            {isLoading ? loadingLabel || intlMessage('shared.i18n.loadingButtonLabel') : label}
          </span>
        )}
        {hasIcon && (
          <S.ButtonIconContainer
            size={size}
            isVisibleLabel={isVisibleLabel}
            hasLeftIcon={hasLeftIcon}
            isLoading={isLoading}
          >
            {isLoading && <Loader hasPrimaryColor={variant === 'filled'} size={size} />}
            {!isLoading && Icon && <Icon size={size} />}
          </S.ButtonIconContainer>
        )}
      </S.ButtonContent>
    </S.Button>
  );
};

export default Button;
