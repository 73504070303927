import { REFRESH_REDUX } from 'src/store/reducers';

import { SteppersType } from 'src/components/ui/stepper/Stepper.types';
import {
  SET_ERROR_NEW,
  SET_VALIDATION_ERROR,
  SET_OFFRE_VALUES,
  SET_OLD_REFERENCES,
  SET_VOLTALIS_PERMISSION,
  SET_EXTERNAL_LEAD_ID,
  SET_LOCATION_VALUES,
  SET_STEPPERS_ROUTES,
  SET_DISALLOW_STEPPERS_ROUTES,
  SET_IS_GROUP_PURCHASE,
} from './constants';

export function setInitLocation(values) {
  return {
    type: SET_LOCATION_VALUES,
    payload: values,
  };
}

export function setOffreValues(values) {
  return {
    type: SET_OFFRE_VALUES,
    payload: values,
  };
}

export function setValidationErrorAction(hasError: boolean) {
  return {
    type: SET_VALIDATION_ERROR,
    payload: hasError,
  };
}

export function setErrorNewAction(error) {
  return {
    type: SET_ERROR_NEW,
    payload: error,
  };
}

export function resetErrorNewAction() {
  return {
    type: SET_ERROR_NEW,
    payload: null,
  };
}

export function setOldReferencesAction(oldReferences) {
  return {
    type: SET_OLD_REFERENCES,
    payload: oldReferences,
  };
}

export function setVoltalisConfirmationAction(payload: boolean) {
  return {
    type: SET_VOLTALIS_PERMISSION,
    payload,
  };
}

export function refreshReduxAction() {
  return {
    type: REFRESH_REDUX,
  };
}

export function setExternalLeadIdAction(payload: string) {
  return {
    type: SET_EXTERNAL_LEAD_ID,
    payload,
  };
}

export function setSteppersRoutes(payload: { steppers: SteppersType; isFirstStepper: boolean }) {
  return {
    type: SET_STEPPERS_ROUTES,
    payload,
  };
}

export function setDisallowStepperRoutes(payload: string[]) {
  return {
    type: SET_DISALLOW_STEPPERS_ROUTES,
    payload,
  };
}

export function setIsGroupPurchase(payload: boolean) {
  return {
    type: SET_IS_GROUP_PURCHASE,
    payload,
  };
}
