import { createSelector } from 'reselect';
import get from 'lodash/get';

import { makeSelectFormField } from 'src/hocs/WithForm/selectors';
import { FORM_NAMES } from 'src/hocs/WithForm/constants';
import { SUBSCRIPTION_FIELDS } from 'src/hocs/WithSubscriptionNew/constants';
import { QUOTATION_FIELDS } from 'src/hocs/WithDevis/constants';
import { isSelectraGroupPurchaseBusinessPart } from 'src/hocs/App/business.helper';
import { AppStateType } from 'src/store/appstate.type';
import { BUSINESS_PARTS } from './constants';

export const selectRoot = (state: AppStateType) => state;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- fix this any once we finally have a type for the State
const selectApp = (state: AppStateType) => state.App;
const selectRoute = (state: AppStateType) => state.router;

export const selectUuid = createSelector(selectApp, (appState) => appState.uuid);

export const selectOffre = createSelector(selectApp, (appState) => appState.offre);

export const selectErrorNew = createSelector(selectApp, (appState) => appState.errorNew);

export const selectCurrentRoute = createSelector(selectRoute, (routeState) => routeState.location);

export const makeSelectLocation = () => selectCurrentRoute;

export const makeSelectLoading = () => createSelector(selectApp, (appState) => appState.loading);

export const makeSelectValidationError = () =>
  createSelector(selectApp, (appState) => appState.validationError);

export const selectBusinessPart = createSelector(selectApp, (substate) =>
  get(substate, 'offre.codeApporteur'),
);

export const selectIsWikiPowerPartner = createSelector(
  selectBusinessPart,
  (substate) => substate === BUSINESS_PARTS.WIKIPOWER,
);

const selectIsSmartavPartner = createSelector(
  selectBusinessPart,
  (substate) => substate === BUSINESS_PARTS.SMARTAV,
);

const selectIsCrdtMtlPartner = createSelector(
  selectBusinessPart,
  (substate) => substate === BUSINESS_PARTS.CRDTMTL,
);

export const selectIsSelectraGroupPurchase = createSelector(selectBusinessPart, (substate) =>
  isSelectraGroupPurchaseBusinessPart(substate),
);

export const makeSelectErrorNew = () => selectErrorNew;

export const selectOldReferences = createSelector(
  selectApp,
  makeSelectFormField(FORM_NAMES.contrat, SUBSCRIPTION_FIELDS.EMAIL),
  (quotation, email) => ({
    [QUOTATION_FIELDS.OLD_CUSTOMER_ID]: get(
      quotation,
      `oldReferences.${QUOTATION_FIELDS.OLD_CUSTOMER_ID}`,
    ),
    [QUOTATION_FIELDS.OLD_CONTRACT_ID]: get(
      quotation,
      `oldReferences.${QUOTATION_FIELDS.OLD_CONTRACT_ID}`,
    ),
    [QUOTATION_FIELDS.EMAIL]: email,
  }),
);

const selectOldCustomerIdFromStore = createSelector(
  selectApp,
  (substate) => substate.oldReferences?.oldCustomerId,
);

export const makeSelectOldCustomerIdFromStore = () => selectOldCustomerIdFromStore;

export const makeSelectBusinessPart = () => selectBusinessPart;

export const makeSelectIsSmartavPartner = () => selectIsSmartavPartner;

export const makeSelectIsCrdtMtlPartner = () => selectIsCrdtMtlPartner;

export const makeSelectOldReferences = () => selectOldReferences;

const selectHasVoltalisUserPermission = createSelector(
  selectApp,
  (subState) => subState?.hasVoltalisUserPermission,
);

export const makeSelectHasVoltalisUserPermission = () => selectHasVoltalisUserPermission;

const selectVoltalisChoiceDone = createSelector(
  selectApp,
  (subState) => subState?.voltalisChoiceDone,
);

export const makeSelectVoltalisChoiceDone = () => selectVoltalisChoiceDone;

export const selectExternalLeadId = createSelector(
  selectApp,
  (appState) => appState.externalLeadId,
);

export const makeSelectExternalLeadId = () => selectExternalLeadId;

const selectSteppersRoutes = createSelector(selectApp, (appState) => appState.steppersRoutes);

export const makeSelectSteppersRoutes = () => selectSteppersRoutes;

export const selectIsUFC2023 = createSelector(
  selectBusinessPart,
  (substate) => substate === BUSINESS_PARTS.UFC2023,
);

const selectIsGroupPurchase = createSelector(selectApp, (appState) => appState.isGroupPurchase);

export const makeSelectIsGroupPurchase = () => selectIsGroupPurchase;
