import Button from 'src/components/ui/button/Button';
import { purgeReduxAndPersistor } from 'src/store/emptyReduxCache';
import Text from 'src/components/ui/typography/text/Text';
import AppConfig from 'src/config/config';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';

const { REACT_APP_HOME_URL } = AppConfig;

export const CongratulationError = () => {
  const { intlMessage } = useIntlMessage();

  const handleClickButton = async () => {
    await purgeReduxAndPersistor();
    window.location.assign(REACT_APP_HOME_URL);
  };

  return (
    <>
      <Text type="l">{intlMessage('app.errorManager.felicitationsErrorMessage1')}</Text>
      <Text type="l">{intlMessage('app.errorManager.felicitationsErrorMessage2')}</Text>
      <br />
      <Button
        onClick={handleClickButton}
        label={intlMessage('app.errorManager.buttonReturnToWebsite')}
      />
    </>
  );
};
