import { jsx } from '@emotion/react/macro';
import { textStyle } from './Text.styles';
import * as T from './Text.types';
import { TYPE_TO_TAG } from './Text.constants';

const Text = ({
  children,
  align,
  type = 'm',
  color,
  weight,
  isItalic,
  margin,
  noWrap = false,
  isUnderline = false,
  ...otherProps
}: T.TextProps) =>
  jsx(TYPE_TO_TAG[type], {
    css: textStyle({
      type,
      weight,
      align,
      noWrap,
      color,
      isItalic,
      margin,
      isUnderline,
    }),
    children,
    ...otherProps,
  });

export default Text;
