import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import { buttonTheme } from 'src/theme/tokens/components/button';
import { AppTheme } from 'src/theme';
import { WithRequired } from 'src/typings/utils';
import * as T from './Button.types';

const { variants, borderWidth, radius, sizes } = buttonTheme;

export const buttonStyles = (
  variant: T.ButtonVariants,
  size: T.ButtonSizes,
  isVisibleLabel: undefined | boolean,
  isInputWithButton = false,
  theme: AppTheme,
) => css`
  align-items: center;
  background: ${variants[variant].background.default};
  border: ${borderWidth} solid ${variants[variant].border.default};
  border-radius: ${radius};
  color: ${variants[variant].color.default};
  cursor: pointer;
  display: inline-flex;
  font-family: ${theme.fonts.family.cta}, sans-serif;
  font-size: ${sizes[size].fontSizes};
  font-weight: ${theme.fonts.weights.bold};
  height: ${sizes[size].height};
  justify-content: center;
  width: ${isInputWithButton ? '100%' : 'unset'};
  margin: 0;
  padding: ${isVisibleLabel ? sizes[size].padding : 'O'};
  text-align: center;
  transition-property: ${theme.transitions.transitionProperty.colors};
  transition-duration: ${theme.transitions.duration.faster};
  transition-timing-function: ${theme.transitions.transitionTimingFunction['in-out']};

  ${theme.breakpoints.upTablet} {
    max-width: ${!isInputWithButton ? '320px' : 'unset'};
    width: unset;
  }

  &:hover {
    background: ${variants[variant].background.interaction};
    border-color: ${variants[variant].border.interaction};
    color: ${variants[variant].color.interaction};
  }

  &:active {
    background: ${variants[variant].background.interaction};
  }

  &:disabled,
  &[disabled] {
    background: ${variants[variant].background.disabled};
    color: ${variants[variant].color.disabled};
    border-color: ${variants[variant].border.disabled};

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const Button = styled.button<WithRequired<Partial<T.StyledProps>, 'variant' | 'size'>>`
  ${({ variant, size, isVisibleLabel, isInputWithButton, theme }) =>
    buttonStyles(variant, size, isVisibleLabel, isInputWithButton, theme)};

  ${({ variant, isSelected, isLoading, isVisibleLabel, size, hasMinWidth }) => css`
    min-width: ${
      hasMinWidth && (isVisibleLabel || (isVisibleLabel && isLoading)) ? '160px' : sizes[size].width
    }
  };

  ${
    isLoading &&
    css`
      background-color: ${variants[variant].background.interaction};
      border-color: ${variants[variant].border.interaction};
      color: ${variants[variant].color.interaction};
    `
  }

  ;

  ${
    isSelected &&
    css`
      background-color: ${variants[variant].background.interaction};
      border-color: ${variants[variant].border.interaction};
      color: ${variants[variant].color.interaction};
    `
  }

  ;
  `};

  ${({ theme }) => theme.breakpoints.upSmall} {
    & + button {
      margin-left: ${({ theme }) => theme.spacing.m};
    }
  }

  ${({ theme }) => theme.breakpoints.small} {
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacing.m};
    }
  }
`;

export const ButtonContent = styled.span<Pick<T.StyledProps, 'hasLeftIcon'>>`
  ${({ hasLeftIcon }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    ${hasLeftIcon && 'flex-direction: row-reverse;'};
  `};
`;

export const ButtonIconContainer = styled.span<
  Pick<T.StyledProps, 'hasLeftIcon' | 'isVisibleLabel' | 'size' | 'isLoading'>
>`
  ${({ size, hasLeftIcon, isVisibleLabel, isLoading, theme }) => css`
    align-items: center;
    display: flex;
    ${isVisibleLabel && !hasLeftIcon && `margin-left: ${theme.spacing.xs};`}
    ${isVisibleLabel && hasLeftIcon && `margin-right: ${theme.spacing.xs};`}
    ${!isVisibleLabel && 'margin: 0;'}
    width: ${!isLoading ? sizes[size].icon : 'auto'};
  `};
`;
