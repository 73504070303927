import styled from '@emotion/styled/macro';
import { inputTextTheme } from 'src/theme/tokens/components/form/inputText';
import { customComponents } from 'src/theme/tokens/components/custom';
import { GetColorArgs } from 'src/components/ui/inputs/dropdown/Dropdown.types';

const { colors } = inputTextTheme;
const { inputSpacing } = customComponents;

const getColor = (disabled: GetColorArgs['disabled'] ) => {
  if (disabled) {
    return colors.text.disabled;
  }
  return colors.text.error;
};

export const Message = styled.span<{ isInputWithButton?: boolean; disabled?: boolean }>`
  display: inline-block;
  margin: ${({ theme }) => `${theme.spacing['2xs']} 0 0 ${theme.spacing[inputSpacing]}`};
  color: ${({ disabled }) => getColor(disabled)};
  font-size: ${({ theme }) => theme.fonts.sizes['2xs']};
  ${({ isInputWithButton }) => isInputWithButton && 'position: absolute'};

  ${({ theme }) => theme.breakpoints.upMedium} {
    font-size: ${({ theme }) => theme.fonts.sizes['3xs']};
    line-height: ${({ theme }) => theme.fonts.lineHeights['3xs']};
  }
`;
