import Button from 'src/components/ui/button/Button';
import { Close } from 'src/components/ui/icons/close/Close';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import * as T from './types';

const CloseButton = ({ onClick, id, 'data-testid': dataTestId }: T.CloseButtonProps) => {
  const { intlMessage } = useIntlMessage();
  return (
    <Button
      aria-label={intlMessage('shared.i18n.closeButtonAriaLabel')}
      data-testid={dataTestId}
      id={id}
      onClick={onClick}
      icon={Close}
      hasMinWidth={false}
      size="l"
      variant="transparent"
    />
  );
};

export default CloseButton;
