import { semantical } from 'src/theme/tokens/semantical';

export const textTheme = {
  colors: {
    attenuated: semantical.colors.text.neutral.weaker,
    primary: semantical.colors.text.primary.strong,
    disabled: semantical.colors.text.state.disabled.weaker,
    error: semantical.colors.text.state.error,
    info: semantical.colors.text.state.info,
    warning: semantical.colors.text.state.warning,
    success: semantical.colors.text.state.success,
    default: semantical.colors.text.neutral.weakest,
    electricity: semantical.colors.text.concept.electricity.weaker,
    gas: semantical.colors.text.concept.gas.weaker,
  },
  desktop: {
    xl: {
      fontSize: semantical.fonts.sizes.xl,
      lineHeight: semantical.fonts.lineHeights.xl,
    },
    l: {
      fontSize: semantical.fonts.sizes.m,
      lineHeight: semantical.fonts.lineHeights.m,
    },
    m: {
      fontSize: semantical.fonts.sizes.s,
      lineHeight: semantical.fonts.lineHeights.xs,
    },
    s: {
      fontSize: semantical.fonts.sizes.xs,
      lineHeight: semantical.fonts.lineHeights['3xs'],
    },
    xs: {
      fontSize: semantical.fonts.sizes['2xs'],
      lineHeight: semantical.fonts.lineHeights['5xs'],
    },
    '2xs': {
      fontSize: semantical.fonts.sizes['3xs'],
      lineHeight: semantical.fonts.lineHeights['5xs'],
    },
    '3xs': {
      fontSize: semantical.fonts.sizes['4xs'],
      lineHeight: semantical.fonts.lineHeights['6xs'],
    },
  },
  mobile: {
    xl: {
      fontSize: semantical.fonts.sizes.l,
      lineHeight: semantical.fonts.lineHeights.l,
    },
    l: {
      fontSize: semantical.fonts.sizes.s,
      lineHeight: semantical.fonts.lineHeights.xs,
    },
    m: {
      fontSize: semantical.fonts.sizes.xs,
      lineHeight: semantical.fonts.lineHeights['3xs'],
    },
    s: {
      fontSize: semantical.fonts.sizes.xs,
      lineHeight: semantical.fonts.lineHeights['4xs'],
    },
    xs: {
      fontSize: semantical.fonts.sizes['3xs'],
      lineHeight: semantical.fonts.lineHeights['6xs'],
    },
    '2xs': {
      fontSize: semantical.fonts.sizes['3xs'],
      lineHeight: semantical.fonts.lineHeights['5xs'],
    },
    '3xs': {
      fontSize: semantical.fonts.sizes['4xs'],
      lineHeight: semantical.fonts.lineHeights['6xs'],
    },
  },
};
