import { LOCATION_CHANGE, connectRouter } from 'connected-react-router';
// Todo: refactoring - replace by react-hook-form
import { reducer as form } from 'redux-form';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createFilter } from 'redux-persist-transform-filter';
import ProductsInformation from 'src/domains/products-information/products-information.slice';
/**
 * Dynamic reducer injection mess a bit with redux-persist
 * So we force this reducer to be present AND persisted
 * to enforce good execution during the refresh process
 * We also include HOC reducer to prevent bug when devs
 * try to use directly actions without injected the reducer
 */
import App from 'src/hocs/App/reducer';
import Cart from 'src/components/organisms/Cart/reducer';
import SellingConditionsReduce from 'src/components/pages/Summary/Form/SellingConditions/reducer';
import SaveProgressionModal from 'src/components/organisms/SaveProgressionModal/reducer';
import LoadingReducer from 'src/components/organisms/LoadingModal/LoadingModal.slice';
import ErrorReducer from 'src/components/organisms/Error/reducer';
import WithEligibility from 'src/hocs/WithEligibilityNew/slice';
import WithPODInfo from 'src/hocs/WithPODInfo/reducer';
import WithAddress from 'src/hocs/WithAddress/reducer';
import WithCarbonImpact from 'src/hocs/WithCarbonImpact/reducer';
import WithDevis from 'src/hocs/WithDevis/reducer';
import WithDevis1kWh from 'src/hocs/WithDevis1kWh/reducer';
import WithForm from 'src/hocs/WithForm/reducer';
import WithElectricMobility from 'src/hocs/WithElectricMobility/reducer';
import WithSubscription from 'src/hocs/WithSubscriptionNew/reducer';
import WithServices from 'src/hocs/WithServices/reducer';
import WithPayments from 'src/hocs/WithPayments/reducer';
import WithConsent from 'src/hocs/WithConsent/reducer';
import WithBilling from 'src/hocs/WithBilling/reducer';
import { WithPromoCodeReducer as WithPromoCode } from 'src/hocs/WithPromoCode/reducer';
import WithUserInformations from 'src/hocs/WithUserInformations/reducer';
import WithCustomerService from 'src/hocs/WithCustomerService/reducer';
import MyDelivery from 'src/components/pages/MyDelivery/MyDelivery.reducer';
import ServicesOptions from 'src/components/pages/ServicesOptions/reducer';
import ChoixEnergie from 'src/components/pages/ChoixEnergieNew/slice';
import LivraisonPod from 'src/components/pages/LivraisonPod/slice';
import PAYMENT from 'src/components/pages/Payment/reducer';
import EstimationFormReducer from 'src/components/pages/Estimation/Form/EstimationReducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = {
  location: null,
};

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return action.payload;
    default:
      return state;
  }
}

// TODO: make match each reducer with a constant key
export const staticReducers = {
  // @ts-ignore TODO js migration
  // eslint-disable-next-line no-restricted-globals
  router: connectRouter(history)(routeReducer),
  // Containers' Reducers
  App,
  Cart,
  SaveProgressionModal,
  LoadingReducer,
  ErrorReducer,
  sellingConditions: SellingConditionsReduce,
  form,
  // HOC's Reducers
  WithAddress,
  WithDevis,
  WithDevis1kWh,
  WithPODInfo,
  WithEligibility,
  WithSubscription,
  WithServices,
  WithForm,
  WithPayments,
  WithConsent,
  WithBilling,
  WithPromoCode,
  WithUserInformations,
  WithCustomerService,
  // Pages' Reducers
  MyDelivery,
  ServicesOptions,
  LivraisonPod,
  PAYMENT,
  ChoixEnergie,
  WithCarbonImpact,
  WithElectricMobility,
  EstimationFormReducer,
  ProductsInformation,
};

export const persistConfig = {
  key: 'EKW',
  storage,
  blacklist: ['router', '_persist', 'LoadingReducer', 'ErrorReducer'],
  transforms: [
    createFilter('App', [
      'offre',
      'customerType',
      'counter',
      'hasVoltalisUserPermission',
      'voltalisChoiceDone',
      'oldReferences',
      'steppersRoutes',
    ]),
  ],
};

/**
 * Creates the main reducer with the dynamically injected ones
 */

export const PURGE_REDUX = 'PURGE_REDUX';
export const REFRESH_REDUX = 'REFRESH_REDUX';

export default function createReducer(injectedReducers) {
  const appReducer = persistCombineReducers(persistConfig, {
    ...staticReducers,
    ...injectedReducers,
  });

  return (state, action) => {
    // when this action is dispatched redux stated is purged
    if (action.type === PURGE_REDUX) {
      // we need to preserve states of some external libraries to avoid nasty errors
      const newState = {
        // eslint-disable-next-line no-underscore-dangle
        _persist: state._persist,
        router: state.router,
      };
      return appReducer(newState, action);
    }
    // This action is to be used in the HomePage, to refresh the entire state.
    // It is similar to PURGE_REDUX, but preserves WithForm reducer, so we can keep PreFill functionality
    // And preserves HOC WithPromoCode so we don't loose promo code submitted by customer
    if (action.type === REFRESH_REDUX) {
      const newState = {
        // eslint-disable-next-line no-underscore-dangle
        _persist: state._persist,
        router: state.router,
        WithForm: state.WithForm,
        App: state.App,
        WithPromoCode: state.WithPromoCode,
      };
      return appReducer(newState, action);
    }
    return appReducer(state, action);
  };
}
