import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useIsDesktop from 'src/hooks/useIsDesktop';
import { makeSelectBusinessPart, selectIsSelectraGroupPurchase } from 'src/hocs/App/selectors';
import { images } from 'src/theme/assets/images';
import { Url } from 'src/utils/routes/routes';
import { isGroupPurchasePath } from 'src/components/pages/GroupPurchase/Landing/logic-helper';
import { useContactChannel } from 'src/hocs/WithCustomerService/hooks';
import Text from 'src/components/ui/typography/text/Text';
import HeaderContact from 'src/components/organisms/Header/HeaderContact';
import { useLocationTitle } from 'src/components/organisms/Header/useLocationTitle';
import { useIntlMessage } from 'src/components/utils/LanguageProvider/intl.helpers';
import * as S from './Header.styles';

const {
  REACT_APP_HOME_URL = '',
  REACT_APP_PARTNER_LOGO_URL = '',
  REACT_APP_STATIC_URL = '',
  REACT_APP_TENANT = '',
} = process.env;

export const Header = (): JSX.Element => {
  const [logoLoadStatus, setLogoLoadStatus] = useState(true);
  const { intlMessage } = useIntlMessage();
  const isDesktop = useIsDesktop();
  const businessPart = useSelector(makeSelectBusinessPart());
  useContactChannel();
  const hasPartnerLogo = businessPart?.toLocaleLowerCase() !== REACT_APP_TENANT && logoLoadStatus;
  const handleLogoLoadError = useCallback(() => {
    setLogoLoadStatus(false);
  }, []);
  const isGroupPurchase = isGroupPurchasePath();
  const isSelectraGroupPurchase = useSelector(selectIsSelectraGroupPurchase);
  const title = useLocationTitle();
  return (
    <S.AppHeader>
      <S.Container>
        <S.AppLogoContainer href={REACT_APP_HOME_URL}>
          <S.AppLogo src={images.appHeaderLogo} alt="" />
        </S.AppLogoContainer>
        {isGroupPurchase && <S.GroupPurchaseLogo src={images.groupPurchaseLogo} alt="" />}
        {!isGroupPurchase && hasPartnerLogo && (
          <S.PartnerLogo
            src={Url(`${REACT_APP_STATIC_URL}${REACT_APP_TENANT}${REACT_APP_PARTNER_LOGO_URL}`, {
              business_part: businessPart,
            })}
            alt={`logo ${businessPart}`}
            onError={handleLogoLoadError}
          />
        )}
        {title && (
          <S.StepTitle type="l" color="attenuated" hasPartnerLogo={hasPartnerLogo}>
            {title}
          </S.StepTitle>
        )}
        {isSelectraGroupPurchase && (
          <S.SelectraGroupPurchaseText>
            <Text align="right">
              {intlMessage('app.header.selectraGroupPurchaseHeading', {
                hasFullText: isDesktop,
              })}
            </Text>
          </S.SelectraGroupPurchaseText>
        )}
        <HeaderContact businessPart={businessPart} isDesktop={isDesktop} />
      </S.Container>
    </S.AppHeader>
  );
};
