import Modal from 'src/components/organisms/Modal';
import { Loader } from 'src/components/ui/loader/Loader';
import { useSelector } from 'react-redux';
import { selectLoading } from './LoadingModal.selectors';

export const LoadingModal = () => {
  const isLoading = useSelector(selectLoading);
  return (
    <Modal id="loadingModal" open={isLoading} withBox={false}>
      <Loader size="xl" />
    </Modal>
  );
};
