import { AxiosResponse } from 'axios';
import { OriginsEnum } from 'src/utils/errorManager/types';
import { EnergyCardsType } from 'src/components/organisms/EnergyCard/types';
import * as T from 'src/hocs/App/types';
import { ValueOf } from 'src/utils/typescript/typescript.helper';
import { CustomerTypeType } from 'src/hocs/WithUserInformations/types';

export interface WithDevisProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  devis: Record<string, any>;
}

/*
 * Redux Action Types
 *
 */

export interface UpdateQuotationActionType {
  type: string;
  payload: QuotationRequestType;
}

export interface QuotationSuccessActionType {
  type: string;
  payload: QuotationResponseType;
}

/**
 *  NEW TYPES
 */

export interface CreateQuotationRequestActionType {
  type: string;
}

export interface CreateQuotationSuccessActionType {
  type: string;
  payload: QuotationResponseType;
}

export interface CreateQuotationFailureActionType {
  type: string;
  payload: AxiosResponse;
}

export interface UpdateQuotationRequestActionType {
  type: string;
}

export interface UpdateQuotationSuccessActionType {
  type: string;
  payload: QuotationResponseType;
}

export interface UpdateQuotationFailureActionType {
  type: string;
  payload: AxiosResponse;
}

/*
 * Enum Types
 *
 */

// Todo: check if all types are included
export enum ElectricityRateEnum {
  HIGH_LOW = 'HIGH_LOW',
  BASE = 'BASE',
  HIGH = 'HIGH',
  LOW = 'LOW',
}

// Todo: use this enum
export enum SupplierInterventionTypeEnum {
  MES = 'MES',
  CHF = 'CHF',
}

export type SupplierInterventionType = ValueOf<typeof SupplierInterventionTypeEnum>;

export enum SupplierInterventionDurationEnum {
  UNDER_48 = 'UNDER_48',
  OVER_48 = 'OVER_48',
  UNKNOWN = 'UNKNOWN',
}

export type SupplierInterventionDurationType = ValueOf<typeof SupplierInterventionDurationEnum>;

export enum ProductItemTypeEnum {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export type OptionItemType = ValueOf<typeof ProductItemTypeEnum>;

export type ProductItemTypeType = ValueOf<typeof AnnualConsumptionOriginEnum>;

export const AnnualConsumptionOriginEnum = {
  SUPPLIER_ESTIMATOR: 'SUPPLIER_ESTIMATOR',
  PROSPECT: 'PROSPECT',
} as const;

type AnnualConsumptionOrigin = ValueOf<typeof AnnualConsumptionOriginEnum>;

export const AnnualConsumptionOriginElectricityEnum = {
  SUPPLIER_DEFAULT: 'SUPPLIER_DEFAULT',
} as const;

type AnnualConsumptionOriginElectricity = ValueOf<typeof AnnualConsumptionOriginElectricityEnum>;

export const AnnualConsumptionOriginGasEnum = {
  DSO: 'DSO',
} as const;

type AnnualConsumptionOriginGas = ValueOf<typeof AnnualConsumptionOriginGasEnum>;

export enum EnergyProductIndexEnum {
  TRV = 'TRV',
  PEG = 'PEG',
}

export type EnergyProductIndexType = ValueOf<typeof EnergyProductIndexEnum>;

/*
 * Other Types
 *
 */

export interface ServiceOptionType {
  annualAmount?: number;
  annualAmountExclVAT?: number;
  annualAmountInclVAT?: number;
  available?: boolean;
  coordinates?: { longitude: number; latitude: number };
  description?: string;
  diffPriceExclVAT?: number;
  diffPriceInclVAT?: number;
  duration?: number;
  energyType?: T.EnergyType;
  familleservices?: string;
  id?: string;
  latitude?: number;
  longitude?: number;
  pictotypecentrale?: string;
  picture?: string;
  quantiterestante?: number;
  serviceAdvantage?: [];
  serviceGroupLabel?: string;
  serviceNotCompatibleWith?: Array<string>;
  title?: string;
  type?: string;
  unitPriceExclVAT?: number;
  unitPriceInclVAT?: number;
}

interface MensualityType {
  annualMonthlyFee: {
    ttc: {
      ELECTRICITY: number;
      GAS: number;
    };
    ht: {
      ELECTRICITY: number;
      GAS: number;
    };
  };
  ttc: PriceArrayType;
  ht: PriceArrayType;
}

interface PriceArrayType {
  ELECTRICITY?: number[];
  GAS?: number[];
}

/*
 * API Call Types
 *
 */

export interface QuotationRequestType {
  energy?: T.EnergyType;
  customerType?: CustomerTypeType;
  city?: string;
  codeInsee?: string;
  zipCode?: string;
  streetNumber?: string;
  streetName?: string;
  sessionId?: string;
  pdl?: string;
  pce?: string;
  offerCode?: string;
  promoCode?: string;
  electricityAnnualConsumptionReferenceBase?: number;
  electricityAnnualConsumptionReferenceLow?: number;
  electricityMeterType?: string; // Todo: create enum with backend team
  electricityPower?: number;
  electricityTariffOption?: ElectricityRateEnum;
  electricityProductItemType?: ProductItemTypeEnum;
  electricityMensuality?: number;
  electricityNextReadingDate?: string;
  electricityDgo?: string;
  gasAnnualConsumptionReference?: number;
  gasProductItemType?: ProductItemTypeEnum;
  gasMensuality?: number;
  gasNextReadingDate?: string;
  gasDgo?: T.GasDistributorsType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services?: Array<any>;
  supplierInterventionType?: SupplierInterventionTypeEnum;
  supplierInterventionDuration?: SupplierInterventionDurationEnum;
  supplierInterventionDate?: string;
  oldCustomerId?: string;
  oldContractId?: string;
  email?: string;
  originElectricityAnnualConsumptionReference?: string;
  originGasAnnualConsumptionReference?: string;
  page?: string | null;
  companyName?: string;
  customer?: string;
}

export interface QuotationResponseType {
  id: string;
  isPriceChanged: boolean;
  offerCode: string;
  promoCode?: string;
  promoCodeValue?: string;
  totalAnnualAmount: number;
  totalAnnualAmountExclVAT: number;
  totalAnnualAmountInclVAT: number;
  totalAnnualAmountSaved: number;
  totalAnnualAmountSavedExclVAT: number;
  totalAnnualAmountSavedInclVAT: number;
  totalAnnualAmountServicesExclVAT: number;
  totalAnnualAmountServicesInclVAT: number;
  totalAnnualAmountSavedWithServicesExclVAT: number;
  totalAnnualAmountSavedWithServicesInclVAT: number;
  totalAnnualAmountWithServicesExclVAT: number;
  totalAnnualAmountWithServicesInclVAT: number;
  totalAnnualTRVAmount: number;
  totalAnnualTRVAmountExclVAT: number;
  totalAnnualTRVAmountInclVAT: number;
  electricityAnnualAmount: number;
  electricityProductItemType: ProductItemTypeEnum;
  electricityProductName: string;
  electricitySubscriptionPriceExclTaxes: number;
  electricityConsumptionBasePriceExclTaxes: number;
  electricityConsumptionLowPriceExclTaxes?: number;
  electricitySubscriptionTrvPriceExclTaxes: number;
  electricityConsumptionBaseTrvPriceExclTaxes: number;
  electricitySubscriptionPriceExclVAT: number;
  electricityConsumptionBasePriceExclVAT: number;
  electricitySubscriptionPriceInclTaxes: number;
  electricityConsumptionBasePriceInclTaxes: number;
  electricityConsumptionLowPriceInclTaxes?: number;
  electricitySubscriptionTRVPriceInclTaxes: number;
  electricityConsumptionBaseTRVPriceInclTaxes: number;
  electricityAnnualTRVAmount: number;
  electricityAnnualAmountSaved: number;
  electricityAnnualAmountExclVAT: number;
  electricityAnnualAmountInclVAT: number;
  electricityAnnualTRVAmountExclVAT: number;
  electricityAnnualTRVAmountInclVAT: number;
  electricityAnnualAmountSavedExclVAT: number;
  electricityAnnualAmountSavedInclVAT: number;
  electricityAnnualAmountSavedWithServicesInclVAT?: number;
  electricityAnnualAmountSavedWithServicesExclVAT?: number;
  electricitySubscriptionPrice?: number;
  electricityConsumptionBasePrice?: number;
  electricityConsumptionLowPrice?: number;
  electricityCta: string;
  electricityVatLowRate: number;
  electricitySlpProfile: string; // Todo: create enum
  electricityTccfeCoefficient: number;
  electricityTdcfeCoefficient: number;
  electricityTcfe1Kwh: number;
  electricityTcfePrice: number;
  electricityTariffTransport: string; // Todo: create enum
  electricityProductIndex: EnergyProductIndexType | null;
  gasProductIndex: EnergyProductIndexType | null;
  gasAnnualAmount?: number;
  gasProductItemType?: ProductItemTypeEnum;
  gasProductName?: string;
  gasRenewablePercent?: number;
  gasSubscriptionPrice?: number;
  gasSubscriptionPriceExclTaxes?: number;
  gasConsumptionBasePriceExclTaxes?: number;
  gasSubscriptionTrvPriceExclTaxes?: number;
  gasConsumptionBaseTrvPriceExclTaxes?: number;
  gasSubscriptionPriceExclVAT?: number;
  gasConsumptionBasePriceExclVAT?: number;
  gasSubscriptionPriceInclTaxes?: number;
  gasConsumptionBasePriceInclTaxes?: number;
  gasSubscriptionTRVPriceInclTaxes?: number;
  gasConsumptionBaseTRVPriceInclTaxes?: number;
  gasAnnualTRVAmount?: number;
  gasAnnualAmountSaved?: number;
  gasAnnualAmountExclVAT?: number;
  gasAnnualAmountInclVAT?: number;
  gasAnnualTRVAmountExclVAT?: number;
  gasAnnualTRVAmountInclVAT?: number;
  gasAnnualAmountSavedExclVAT?: number;
  gasAnnualAmountSavedInclVAT?: number;
  gasAnnualAmountWithServicesExclVAT?: number;
  gasAnnualAmountWithServicesInclVAT?: number;
  gasAnnualAmountSavedWithServicesExclVAT?: number;
  gasAnnualAmountSavedWithServicesInclVAT?: number;
  electricityAnnualAmountWithServicesExclVAT?: number;
  electricityAnnualAmountWithServicesInclVAT?: number;
  gasCta?: number;
  gasVatLowRate?: number;
  gasConsumptionClassTRV?: string;
  gasSlpProfile?: string;
  gasTariffTransport?: string;
  zipCode: string;
  city: string;
  streetNumber: string;
  streetName: string;
  energy: T.EnergyType;
  electricityPower: number;
  electricityTariffOption: ElectricityRateEnum;
  isElectricityTariffOptionChange: boolean;
  electricityAnnualConsumptionReferenceBase: number;
  electricityAnnualConsumptionReferenceLow?: number;
  gasAnnualConsumptionReference?: number;
  gasTariffZone?: string;
  codeInsee: string;
  electricityCspePrice: number;
  electricityVatHighRate: number;
  gasVatHighRate?: number;
  sessionId: string;
  customerType: CustomerTypeType;
  electricityDgo: T.ElectricityDistributorsType;
  gasDgo?: T.GasDistributorsType;
  electricityMeterType: T.MeterTypeCodeType;
  gasMeterType: T.MeterTypeCodeType;
  pdl: string;
  pce?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  services: any[];
  supplierInterventionType: SupplierInterventionTypeEnum;
  supplierInterventionDuration?: SupplierInterventionDurationEnum;
  mensuality: MensualityType;
  electricityContractualState: string; // Todo: create enum
  gasContractualState?: string;
  electricityTicState: string;
  sellerId?: string;
  oldCustomerId?: string;
  oldContractId?: string;
  electricityMensuality?: number;
  gasMensuality?: number;
  gasConsumptionBasePriceExclVATWithBioGas?: number;
  gasConsumptionBasePriceInclTaxesWithBioGas?: number;
  gasConsumptionBasePriceExclTaxesWithBioGas?: number;
  isElectricityIndexNeeded?: boolean;
  isGasIndexNeeded?: boolean;
  originElectricityAnnualConsumptionReference?:
    | AnnualConsumptionOrigin
    | AnnualConsumptionOriginElectricity
    | null;
  originGasAnnualConsumptionReference?: AnnualConsumptionOrigin | AnnualConsumptionOriginGas | null;
  finalOriginElectricityAnnualConsumptionReference?:
    | AnnualConsumptionOrigin
    | AnnualConsumptionOriginGas
    | null;
  finalOriginGasAnnualConsumptionReference?:
    | AnnualConsumptionOrigin
    | AnnualConsumptionOriginGas
    | null;
  hasAcceptedElectricityAnnualReferenceConsumption?: boolean;
  hasAcceptedGasAnnualReferenceConsumption?: boolean;
  supplierInterventionDate?: string;
}

/**
 * Quotation with additional computed properties. This is not in the response type
 * because it is not provided by the API.
 */
export interface QuotationEnhancedType extends QuotationResponseType {
  totalAnnualAmountWithServices: number;
  totalAnnualAmountServices: number;
  electricitySubscriptionPrice: number;
  electricityConsumptionBasePrice: number;
  electricityConsumptionLowPrice: number;
  gasConsumptionBasePrice: number;
  gasSubscriptionPrice: number;
  lastName: string;
  firstName: string;
}

/*
    Reducer
 */

export const InvoiceFrequencyEnum = {
  MENS: 'MENS',
  ANN: 'ANN',
  BIM: 'BIM',
};

const MensualityIndexEnum = {
  LOW: 0,
  MIDDLE: 1,
  HIGH: 2,
} as const;

export interface QuotationReducerType {
  loading?: {
    step: number;
  };
  devis?: QuotationResponseType | null;
  offre?: {
    codeOffre: string | null;
    codeCanal: string;
    codeApporteur: string;
  };
  loadingQuotation: boolean;
  errorQuotation: null | AxiosResponse;
  mensualityIndex: ValueOf<typeof MensualityIndexEnum>;
}

/*
    Selector types
 */

export interface GetCAROriginTypes {
  page?:
    | ValueOf<typeof OriginsEnum.HOME_PAGE>
    | ValueOf<typeof OriginsEnum.CHOIX_ENERGIE>
    | ValueOf<typeof OriginsEnum.ESTIMATION>
    | null;
  energyType?: typeof T.EnergyEnum.ELECTRICITY | typeof T.EnergyEnum.GAS;
  energyCardValue?: EnergyCardsType.CONSUMPTION_CARD;
  originFromQuotation?:
    | AnnualConsumptionOrigin
    | AnnualConsumptionOriginElectricity
    | AnnualConsumptionOriginGas
    | null;
}
