import * as T from './Divider.types';
import * as S from './Divider.styles';

const Divider = ({
  size = 'full',
  variant = 'primary',
  word,
  margin = 'm',
  wordBackground = 'primary',
  className,
}: T.DividerProps) => (
  <S.DividerContainer>
    <S.Divider variant={variant} size={size} word={word} margin={margin} className={className} />
    {word && <S.DividerWord wordBackground={wordBackground}>{word}</S.DividerWord>}
  </S.DividerContainer>
);

export default Divider;
