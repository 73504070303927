import React from 'react';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

interface MaskedInputProps {
  name?: string;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: any;
  defaultValue?: string;
  mask: string;
  id: string;
}

/**
 * @deprecated in favor of MaskedInput
 */
const MaskedInputDeprecated: React.FC<MaskedInputProps> = ({
  name,
  onChange,
  control,
  defaultValue,
  mask,
  id,
}: MaskedInputProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    as={<IMaskInput mask={mask} onAccept={onChange} id={id} />}
  />
);

export default MaskedInputDeprecated;
