import { ValueOf } from 'src/utils/typescript/typescript.helper';

// TODO : when Devis refacto is finished, remove this constants
export const SET_LOCATION_VALUES = 'app/App/SET_LOCATION_VALUES';
export const SET_OLD_REFERENCES = 'app/App/SET_OLD_REFERENCES';
export const SET_DISPLAY_INSEE = 'app/App/SET_DISPLAY_INSEE';
export const SET_OFFRE_VALUES = 'app/App/SET_OFFRE_VALUES';
export const SET_ERROR_NEW = 'app/App/SET_ERROR_NEW';
export const SET_EXTERNAL_LEAD_ID = 'app/App/SET_EXTERNAL_LEAD_ID';
export const LOADING = 'app/App/Loading';
export const RESET_STATE = 'app/App/RESET_STATE';
export const SET_FULL_OFFER = 'app/App/SET_FULL_OFFER';
export const SET_VALIDATION_ERROR = 'app/App/SET_VALIDATION_ERROR';
export const SET_VOLTALIS_PERMISSION = 'app/App/SET_VOLTALIS_PERMISSION';
export const SET_IS_GROUP_PURCHASE = 'app/App/SET_IS_GROUP_PURCHASE';
export const SET_STEPPERS_ROUTES = 'app/App/SET_STEPPERS_ROUTES';
export const SET_DISALLOW_STEPPERS_ROUTES = 'app/App/SET_DISALLOW_STEPPERS_ROUTES';
export const SET_COUNTER = 'SET_COUNTER';
export const PRO_CUSTOMER_TAX_KEY = 'Excl';
export const PRIVATE_CUSTOMER_TAX_KEY = 'Incl';
export const BUSINESS_PARTS = {
  AGACHTGRP24: 'AGACHTGRP24',
  BUTAGAZ: 'BUTAGAZ',
  CRDTMTL: 'CRDTMTL',
  EKWATEUR: 'EKWATEUR',
  HELLOWT: 'HELLOWT',
  LCLPRO: 'LCLPRO',
  MEILLEUR_TAUX: 'AGMTAUX21',
  MTAUX: 'MTAUX',
  PAPERNEST: 'VPPPNST24',
  SELECTRA: 'SELECTRA',
  SELECTRABIOGAZ: 'SELECTRABIOGAZ',
  SELECTRAPRINTEMPS2022: 'SELECTRAPRINTEMPS2022',
  SELECTRA_GROUP_PURCHASE: {
    AGHIVER23: 'AGHIVER23',
    SEL_JELOUEBIEN: 'SEL-JELOUEBIEN',
    SEL_BANKIN: 'SEL-BANKIN',
    SEL_UNEO: 'SEL-UNEO',
    SEL_IAA: 'SEL-IAA',
    SEL_DIRASS: 'SEL-DIRASS',
    SEL_YOUNITED: 'SEL-YOUNITED',
    SEL_CITYA: 'SEL-CITYA',
    SEL_CENTU21: 'SEL-CENTU21',
  },
  SMARTAV: 'SMARTAV',
  STRATRV2022A: 'STRATRV2022A',
  UFC2023: 'UFC2023',
  WELCOMEBACK: 'WELCOMEBACK',
  WIKIPOWER: 'AGWIKI21Q2',
};

export type BusinessPartType = ValueOf<typeof BUSINESS_PARTS>;

export const MODAL_ERROR_ID = 'AppErrorModal';
