import { useIntl } from 'react-intl';
import Modal from 'src/components/organisms/Modal';
import Text from 'src/components/ui/typography/text/Text';
import { ErrorComponents } from 'src/components/organisms/ErrorModal/ErrorModal.mapping';
import { ErrorDefinitionType } from 'src/utils/errorManager/types';
import { images } from 'src/theme/assets/images';

interface ErrorModalProps {
  error: ErrorDefinitionType;
  id?: string;
  onClose: () => void;
  customErrorImage?: string;
}

export const ErrorModal = (props: ErrorModalProps) => {
  const { error, id, onClose } = props;
  const { errorMessage, headlineMessage, isClosable, html, customErrorImage } = error || {};
  const { formatMessage } = useIntl();

  const ModalContentHTML = html ? ErrorComponents[html] : undefined;

  return (
    <Modal
      id={id}
      open={!!error}
      closable={isClosable}
      onClose={onClose}
      illustration={customErrorImage || images.networkError}
      headingText={headlineMessage && formatMessage(headlineMessage)}
    >
      {ModalContentHTML ? (
        <ModalContentHTML {...error.htmlProps} />
      ) : (
        errorMessage && <Text>{errorMessage}</Text>
      )}
    </Modal>
  );
};
