import { HeadingType } from './Heading.types';
import { Tag } from '../utils/Typography.types';

export const TYPE_TO_TAG: { [key in HeadingType]: Tag } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
} as const;
